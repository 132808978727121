<template>
    <div>
        <!--导入上账记录-->
        <GlobalTable ref="merchantTable" v-loading="loading" :columns="tableColumns4" :data="this.seachDataList"
            :currentPage="page.pageNum" :total="page.total" @handleCurrentChange="handleCurrentChange"
            v-if="this.up === '01' && this.record == '01'">
            <el-table-column label="批次号" slot="uniqNo" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.batchNo || "--" }}</span>
                </template>
            </el-table-column>
            <el-table-column label="导入备注" slot="orderNo" align="center" width="100rpx">
                <template slot-scope="{ row }">
                    <div>
                        <span>{{ row.note || "- -" }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="商户类型" slot="shop" align="center" width="100rpx">
                <template slot-scope="{ row }">
                    <span>{{ row.accountType == "merchant" ? "回收商" : "门店商" }}</span>
                </template>
            </el-table-column>
            <el-table-column label="上账类型" slot="opt" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.typeName }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作人" slot="title" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.adminName }} - {{ row.adminPhone }}</span>
                </template>
            </el-table-column>
            <el-table-column label="导入商家" slot="shopnum" align="center">
                <template slot-scope="{ row }">
                <span>{{ row.enterpriseNum }}个</span>
                </template>
            </el-table-column>
            <el-table-column label="是否操作上账" slot="amount" align="center" width="120">
                <template slot-scope="{ row }">
                    <span v-if="row.isOpt" style="color: rgb(9, 129, 255);">是</span>
                    <span v-if="!row.isOpt">否</span>
                </template>
            </el-table-column>
            <!-- <el-table-column label="当时账户结余" slot="nowBalance" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.afterBalance || "--" }}</span>
                </template>
            </el-table-column> -->
            <el-table-column label="上账执行状态" slot="redbageBalance" align="center">
                <template slot-scope="{ row }">
                    <span v-if='row.state == "00"'>待执行</span>
                    <span v-if='row.state == "01"'>执行中</span>
                    <span v-if='row.state == "02"'>执行成功</span>
                    <span v-if='row.state == "10"'>执行失败</span>
                </template>
            </el-table-column>
            <el-table-column label="上账任务情况" width="130rpx" slot="use" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.successNum }}个成功,{{ row.failNum }}个失败</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="250" slot="remark" align="center">
                <template slot-scope="{ row }">
                    <div style="display: flex;">
                        <el-button type="danger" round v-if="!row.isOpt" size="small"
                            @click="operateaccount(row)">操作上账</el-button>
                        <el-button type="danger" round plain v-if="row.isOpt" size="small"
                            @click="operateaccount(row)">查看上账记录</el-button>
                        <el-button type="success" round size="small" @click="download(row.batchNo)">下载导入数据</el-button>
                    </div>
                </template>
            </el-table-column>
        </GlobalTable>
        <!--上账记录-->
        <GlobalTable ref="upaccounttable" v-loading="upaccountloading" :columns="tableColumns"
            :data="this.seachDataList1" :currentPage="page1.pageNum" :total="page1.total"
            @handleCurrentChange="handleCurrentChange" v-if="this.up === '01' && this.record == '02'">
            <el-table-column label="导入批次号" slot="uniqNo" align="center">
                <template slot-scope="{ row }">
                    <span style="border-bottom: 1px solid rgb(9, 129, 255);
    color: rgb(9, 129, 255);
    cursor: pointer;" @click="gotonext(row)">{{ row.batchNo || "--" }}</span>
                </template>
            </el-table-column>
            <el-table-column label="上账金额" slot="orderNo" align="center" width="100rpx">
                <template slot-scope="{ row }">
                    <div>
                        <span>{{ row.amountPrice || "- -" }}</span>

                    </div>
                </template>
            </el-table-column>
            <el-table-column label="上账类型" slot="opt" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.typeName || "- -" }}</span>
                </template>
            </el-table-column>
            <el-table-column label="上账回收商" slot="title" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.enterpriseName }}</span>
                </template>
            </el-table-column>
            <el-table-column label="上账状态" slot="amount" align="center">
                <template slot-scope="{ row }">
                    <div v-if="row.state == '00'">待执行</div>
                    <div v-if="row.state == '01'">上账成功</div>
                    <div v-if="row.state == '10'">上账失败</div>
                    <div style="
    color: rgb(9, 129, 255);
    cursor: pointer;" v-if="row.state == '01'" @click="seeItem(row)">查看账务记录</div>
                </template>
            </el-table-column>
            <!-- <el-table-column label="当时账户结余" slot="nowBalance" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.afterBalance || "--" }}</span>
                </template>
            </el-table-column> -->
            <el-table-column label="上账备注" slot="redbageBalance" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.note }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作人" width="130rpx" slot="use" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.adminName }}-{{ row.adminPhone }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作时间" width="150px" slot="remark" align="center">
                <template slot-scope="{ row }">
                    <div style="display: flex;">
                        {{ row.createTime }}
                    </div>
                </template>
            </el-table-column>
        </GlobalTable>
        <!--下账记录-->
        <GlobalTable ref="downaccounttable" v-loading="downaccountloading" :columns="tableColumns2"
            :data="this.seachDataList2" :currentPage="page3.pageNum" :total="page3.total"
            @handleCurrentChange="handleCurrentChangedown" v-if="this.up === '02' && this.record == '02'">
            <el-table-column label="导入批次号" slot="uniqNo" align="center">
                <template slot-scope="{ row }">
                    <span style="border-bottom: 1px solid rgb(9, 129, 255);
    color: rgb(9, 129, 255);
    cursor: pointer;" @click="gotonext1(row)">{{ row.batchNo || "--" }}</span>
                </template>
            </el-table-column>
            <el-table-column label="下账金额" slot="orderNo" align="center" width="100rpx">
                <template slot-scope="{ row }">
                    <div>
                        <span>{{ row.amountPrice || "- -" }}</span>

                    </div>
                </template>
            </el-table-column>
            <el-table-column label="下账类型" slot="opt" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.typeName || "- -" }}</span>
                </template>
            </el-table-column>
            <el-table-column label="下账回收商" slot="title" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.enterpriseName }}</span>
                </template>
            </el-table-column>
            <el-table-column label="下账状态" slot="amount" align="center">
                <template slot-scope="{ row }">
                    <div v-if="row.state == '00'">待执行</div>
                    <div v-if="row.state == '01'">下账成功</div>
                    <div v-if="row.state == '10'">下账失败</div>
                    <div style="
    color: rgb(9, 129, 255);
    cursor: pointer;" v-if="row.state == '01'" @click="seeItem1(row)">查看账务记录</div>
                </template>
            </el-table-column>
            <!-- <el-table-column label="当时账户结余" slot="nowBalance" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.afterBalance || "--" }}</span>
                </template>
            </el-table-column> -->
            <el-table-column label="下账备注" slot="redbageBalance" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.note }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作人" width="130rpx" slot="use" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.adminName }}-{{ row.adminPhone }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作时间" width="150px" slot="remark" align="center">
                <template slot-scope="{ row }">
                    <div style="display: flex;">
                        {{ row.createTime }}
                    </div>
                </template>
            </el-table-column>
        </GlobalTable>
        <!--导入下账记录-->
        <GlobalTable ref="downmerchantTable" v-loading="downloading" :columns="tableColumns1"
            :data="this.downseachDataList" :currentPage="page2.pageNum" :total="page2.total"
            @handleCurrentChange="handleCurrentChangedown" v-if="this.up === '02' && this.record == '01'">
            <el-table-column label="批次号" slot="uniqNo" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.batchNo || "--" }}</span>
                </template>
            </el-table-column>
            <!-- <el-table-column label="下账金额" slot="orderNo" align="center" width="100rpx">
                <template slot-scope="{ row }">
                    <div>
                        <span>{{ row.amountPrice || "- -" }}</span>
                    </div>
                </template>
            </el-table-column> -->
            <el-table-column label="导入备注" slot="orderNo" align="center" width="100rpx">
                <template slot-scope="{ row }">
                    <div>
                        <span>{{ row.note || "- -" }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="导入商家" slot="shopnum" align="center">
                <template slot-scope="{ row }">
                <span>{{ row.enterpriseNum }}个</span>
                </template>
            </el-table-column>
            <el-table-column label="商户类型" slot="shop" align="center" width="100rpx">
                <template slot-scope="{ row }">
                    <span>{{ row.accountType == "merchant" ? "回收商" : "门店商" }}</span>
                </template>
            </el-table-column>
            <el-table-column label="下账类型" slot="opt" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.typeName }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作人" slot="title" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.adminName }} - {{ row.adminPhone }}</span>
                </template>
            </el-table-column>
            <el-table-column label="是否操作下账" slot="amount" align="center">
                <template slot-scope="{ row }">
                    <span v-if="row.isOpt" style="color: rgb(9, 129, 255);">是</span>
                    <span v-if="!row.isOpt">否</span>
                </template>
            </el-table-column>
            <!-- <el-table-column label="当时账户结余" slot="nowBalance" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.afterBalance || "--" }}</span>
                </template>
            </el-table-column> -->
            <el-table-column label="下账执行状态" slot="redbageBalance" align="center">
                <template slot-scope="{ row }">
                    <span v-if='row.state == "00"'>待执行</span>
                    <span v-if='row.state == "01"'>执行中</span>
                    <span v-if='row.state == "02"'>执行成功</span>
                    <span v-if='row.state == "10"'>执行失败</span>
                </template>
            </el-table-column>
            <el-table-column label="下账任务情况" width="130rpx" slot="use" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.successNum }}个成功,{{ row.failNum }}个失败</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="215rpx" slot="remark" align="center">
                <template slot-scope="{ row }">
                    <div style="display: flex;">
                        <el-button type="danger" round v-if="!row.isOpt" size="small"
                            @click="operateaccount1(row)">操作下账</el-button>
                        <el-button type="danger" round plain v-if="row.isOpt" size="small"
                            @click="operateaccount1(row)">查看下账记录</el-button>
                        <el-button type="success" round size="small" @click="download1(row.batchNo)">下载导入数据</el-button>
                    </div>
                </template>
            </el-table-column>
        </GlobalTable>
        <!-- 操作上账 -->
        <el-dialog :title="accountTitle" :visible.sync="accountVisible" :close-on-click-modal="false"
            @close="closeaccount">
            <el-table ref="singleTable" highlight-current-row style="width: 100%" :data="brandDataList" :maxHeight="700"
                stripe border>
                <el-table-column type="index" width="50" label="序号" align="center">
                </el-table-column>
                <el-table-column prop="enterpriseName" label="上账回收商" width="120" align="center">
                    <template slot-scope="{ row }">
                        <span>{{ row.enterpriseName || "- -" }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="contacts" label="联系人" width="120" align="center">
                    <template slot-scope="{ row }">
                        <span>{{ row.contacts || "- -" }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="contactsPhone" label="联系电话" align="center">
                    <template slot-scope="{ row }">
                        <span>{{ row.contactsPhone || "- -" }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="amountPrice" label="上账金额" align="center">
                    <template slot-scope="{ row }">
                        <span style="color:rgb(255, 104, 123)">{{ `${row.amountPrice}元` || "- -" }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="note" label="上账备注" align="center" width="300">
                    <template slot-scope="{ row }">
                        <!-- <span>{{ row.note || "- -" }}</span> -->
                        <el-input v-model="row.note" placeholder="请输入内容" :disabled="row.state !== '00'"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="state" label="执行状态" align="center">
                    <template slot-scope="{ row }">
                        <span>{{ row.state == "00" ? "待执行" : ""
                            }}</span>
                                  <span>{{ row.state ==  "01" ? "上账成功"  : ""
                            }}</span>
                                  <!-- <span>{{ row.state == "00" ? "未开始" : "01" ? "下账成功" : "02" ? "下账成功" : "10" ? "下账失败" : "- -"
                            }}</span> -->
                                  <span>{{ row.state == "10" ? "上账失败" : ""
                            }}</span>
                    </template>
                </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button @click.native="closeaccount">{{ closebutonname }}</el-button>
                <el-button type="primary" @click.native="accountupordown" v-if="isshowok" :loading="okloading">{{
                    okbuttonname
                }}</el-button>
            </span>
        </el-dialog>
        <!-- 操作下账 -->
        <el-dialog :title="accountTitle1" :visible.sync="accountVisible1" :close-on-click-modal="false"
            @close="closeaccount1">
            <el-table ref="singleTable" highlight-current-row style="width: 100%" :data="brandDataList1"
                :maxHeight="700" stripe border>
                <el-table-column type="index" width="50" label="序号" align="center">
                </el-table-column>
                <el-table-column prop="enterpriseName" label="下账回收商" width="120" align="center">
                    <template slot-scope="{ row }">
                        <span>{{ row.enterpriseName || "- -" }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="contacts" label="联系人" width="120" align="center">
                    <template slot-scope="{ row }">
                        <span>{{ row.contacts || "- -" }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="contactsPhone" label="联系电话" align="center">
                    <template slot-scope="{ row }">
                        <span>{{ row.contactsPhone || "- -" }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="amountPrice" label="下账金额" align="center">
                    <template slot-scope="{ row }">
                        <span style="color:rgb(255, 104, 123)">{{ `${row.amountPrice}元` || "- -" }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="note" label="下账备注" align="center" width="300">
                    <template slot-scope="{ row }">
                        <!-- <span>{{ row.note || "- -" }}</span> -->
                        <el-input v-model="row.note" placeholder="请输入内容" :disabled="row.state !== '00'"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="state" label="执行状态" align="center">
                    <template slot-scope="{ row }">
                        <span>{{ row.state == "00" ? "待执行" : ""
                            }}</span>
                                  <span>{{ row.state ==  "01" ? "下账成功"  : ""
                            }}</span>
                                  <!-- <span>{{ row.state == "00" ? "未开始" : "01" ? "下账成功" : "02" ? "下账成功" : "10" ? "下账失败" : "- -"
                            }}</span> -->
                                  <span>{{ row.state == "10" ? "下账失败" : ""
                            }}</span>
                    </template>
                </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button @click.native="closeaccount1">{{ closebutonname1 }}</el-button>
                <el-button type="primary" @click.native="accountupordown1" v-if="isshowok1" :loading="okloading1">{{
                    okbuttonname1
                }}</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import _api from "@/utils/request";
import { mode } from "crypto-js";
import { _http } from "../../../utils/request/bill";
import { SkeletonItem } from "element-ui";
import moment from "moment";
export default {
    name: "upDownLoadList",
    props: {
        up: {
            type: String
        },
        record: {
            type: String
        },
    },
    data() {
        return {
            isExport: false,
            loading: false,
            seachDataList: [],
            page: {
                pageNum: 1,
                pageSize: 10
            },
            page1: {
                pageNum: 1,
                pageSize: 10
            },
            page2: {
                pageNum: 1,
                pageSize: 10
            },
            page3: {
                pageNum: 1,
                pageSize: 10
            },
            pageNum: 1,
            tableColumns1: [
                { slotName: "uniqNo" },
                { slotName: "shop" },
                { slotName: "opt" },
                { slotName: "title" },
                { label: "导入时间", prop: "createTime" },
                { slotName: "orderNo" },
                {slotName:"shopnum"},
                { slotName: "amount" },
                { slotName: "nowBalance" },
                { slotName: "redbageBalance" },
                { slotName: "use" },
                { slotName: "remark" }
            ],
            tableColumns4: [
                { slotName: "uniqNo" },
                { slotName: "shop" },
                { slotName: "opt" },
                { slotName: "title" },
                { label: "导入时间", prop: "createTime" },
                { slotName: "orderNo" },
                {slotName:"shopnum"},
                { slotName: "amount" },
                { slotName: "nowBalance" },
                { slotName: "redbageBalance" },
                { slotName: "use" },
                { slotName: "remark" }
            ],
            tableColumns: [
                { slotName: "uniqNo" },
                { slotName: "shop" },
                { slotName: "opt" },
                { slotName: "title" },
                { slotName: "orderNo" },
                { slotName: "amount" },
                { slotName: "nowBalance" },
                { slotName: "redbageBalance" },
                { slotName: "use" },
                { slotName: "remark" }
            ],
            tableColumns2: [
                { slotName: "uniqNo" },
                { slotName: "shop" },
                { slotName: "opt" },
                { slotName: "title" },
                { slotName: "orderNo" },
                { slotName: "amount" },
                { slotName: "nowBalance" },
                { slotName: "redbageBalance" },
                { slotName: "use" },
                { slotName: "remark" }
            ],
            accountDialogVisible: false,//调账对话框
            ruleForm: {
                price: '',
                command: '',
                companyId: "",
            },
            rules: {
                price: [
                    { required: true, message: '请输入大于等于0的数,可保留2位小数', trigger: 'blur' },
                ],
                command: [
                    { required: true, message: '请输入谷歌验证码', trigger: 'blur' }
                ],
                companyId: [
                    { required: true, message: '请选择门店商', trigger: 'blur' }
                ]
            },
            diffFundBalance: 0,
            accountTitle: "操作上账-未开始",//操作上下账弹框
            accountTitle1: "操作下账-未开始",//
            accountVisible: false,
            brandColumns: [{
                label: '指定品牌', prop: 'name'
            }],
            brandDataList: [],
            operateobj: null,//操作表格中的某行数据
            closebutonname: "取消",
            closebutonname1: "取消",
            okbuttonname: "确定上账",
            okbuttonname1: "确定下账",
            isshowok: true,
            isshowok1: true,
            okloading: false,
            okloading1: false,
            upaccountloading: false,
            downaccountloading: false,
            seachDataList1: [],
            downloading: false,
            downseachDataList: [],//导入下账记录
            accountVisible1: false,
            brandDataList1: [],
            operateobj1: null,//操作表格中的某行数据
            seachDataList2: [],
        };
    },
    created() {
        this.handleCurrentChange();
    },
    computed: {
        SeachParams() {
            return this.$store.state.tagsView.SeachParams
        },
    },
    watch: {
        SeachParams(newVal) {
            console.log(newVal)
            if (newVal) {
                this.page.pageNum = 1;
                this.handleCurrentChange()
                this.handleCurrentChangedown()
            }  
        },
        deep:true
    },
    methods: {
        // 表格切换页面
        handleCurrentChange(val) {
            
            if (val) {
                this.pageNum = val
                this.page.pageNum = val;
            }
            let baseRequest
            if (this.up == "01" && this.record == "01") {
                let dateTime= [moment()
                .day(moment().day())
                .format("YYYY-MM-DD 00:00:00"),
            moment().add(30, 'days').format("YYYY-MM-DD 00:00:00")]
           let start=moment(dateTime[0]).format("x");
           let end=moment(dateTime[0]).format("x");
                baseRequest = {
                    accountType: this.SeachParams.accountType,
                    batchNo: this.SeachParams.batchNo,
                    endTime: this.SeachParams.endTime?this.SeachParams.endTime:end,
                    isOpt: this.SeachParams.isOpt,
                    opt: this.SeachParams.opt,
                    startTime: this.SeachParams.startTime?this.SeachParams.startTime:start,
                    state: this.SeachParams.state,
                    pageNum: this.page.pageNum,
                    pageSize: this.page.pageSize,
                };
                this.loading = true
                _http.dispatchUpDownList(baseRequest).then(res => {
                    if (res.code === 1) {
                        console.log(res, 9999)
                        this.seachDataList = res.data.records;
                        this.page.total = res.data.total;
                        this.page.pageNum = res.data.current;

                    }
                }).finally(() => {
                    this.loading = false
                    if (typeof this.SeachParams.disuseLoding == "function") {
                        this.SeachParams.disuseLoding();
                    }
                });
            }
            if (this.up == "01" && this.record == "02") {
                baseRequest = {
                    accountType: this.SeachParams.accountType,
                    batchNo: this.SeachParams.batchNo,
                    endTime: this.SeachParams.endTime,
                    opt: this.SeachParams.opt,
                    pageNum: 1,
                    pageSize: 10,
                    startTime: this.SeachParams.startTime,
                    enterpriseId: this.SeachParams.enterpriseId,
                    adminId: this.SeachParams.adminId
                };
                this.upaccountloading = true
                _http.dispatchAccountList(baseRequest).then(res => {
                    if (res.code === 1) {
                        console.log(res, 9999)
                        this.seachDataList1 = res.data.records;
                        this.page1.total = res.data.total;
                        this.page1.pageNum = res.data.current;
                        // this.loading = false
                    }
                }).finally(() => {
                    this.upaccountloading = false
                    if (typeof this.SeachParams.disuseLoding == "function") {
                        this.SeachParams.disuseLoding();
                    }
                });
            }

        },
        // 表格切换页面
        handleCurrentChangedown(val) {
            console.log(this.SeachParams, 99999)
            if (val) {
                this.pageNum = val
                this.page.pageNum = val;
            }
            let baseRequest
            if (this.up == "02" && this.record == "01") {
                console.log(6666)
                baseRequest = {
                    accountType: this.SeachParams.accountType,
                    batchNo: this.SeachParams.batchNo,
                    endTime: this.SeachParams.endTime,
                    isOpt: this.SeachParams.isOpt,
                    opt: this.SeachParams.opt,
                    startTime: this.SeachParams.startTime,
                    state: this.SeachParams.state,
                    pageNum: this.page.pageNum,
                    pageSize: this.page.pageSize,
                };
                this.downloading = true
                _http.dispatchUpDownList(baseRequest).then(res => {
                    if (res.code === 1) {
                        console.log(res, 9999)
                        this.downseachDataList = res.data.records;
                        this.page2.total = res.data.total;
                        this.page2.pageNum = res.data.current;

                    }
                }).finally(() => {
                    this.downloading = false
                    if (typeof this.SeachParams.disuseLoding == "function") {
                        this.SeachParams.disuseLoding();
                    }
                });
            }
            if (this.up == "02" && this.record == "02") {
                baseRequest = {
                    accountType: this.SeachParams.accountType,
                    batchNo: this.SeachParams.batchNo,
                    endTime: this.SeachParams.endTime,
                    opt: this.SeachParams.opt,
                    pageNum: 1,
                    pageSize: 10,
                    startTime: this.SeachParams.startTime,
                    enterpriseId: this.SeachParams.enterpriseId,
                    adminId: this.SeachParams.adminId
                };
                this.downaccountloading = true
                _http.dispatchAccountList(baseRequest).then(res => {
                    if (res.code === 1) {
                        console.log(res, 9999)
                        this.seachDataList2 = res.data.records;
                        this.page3.total = res.data.total;
                        this.page3.pageNum = res.data.current;
                        // this.loading = false
                    }
                }).finally(() => {
                    this.downaccountloading = false
                    if (typeof this.SeachParams.disuseLoding == "function") {
                        this.SeachParams.disuseLoding();
                    }
                });
            }

        },
        toOrderDetails(row) {
            this.$router.push({ path: "/RecallOrder/Details", query: { type: 'edit', id: row.orderNo } })
        },
        //打开上账弹框
        handBtn() {
            this.accountDialogVisible = true
        },
        //下载导入数据
        download(batchNo) {
            _http.dispatchExcelImport({ batchNo: batchNo, pageNum: 1, pageSize: 10 }).then(res => {
                const content = res;
                const blob = new Blob([content]);
                let reader = new FileReader(); // 创建读取文件对象
                reader.addEventListener("loadend", () => {
                    try {
                        let data = JSON.parse(reader.result);
                        if (data.code === 0) {
                            this.$message.error(data.msg || "服务异常");
                        }
                    } catch (err) {
                        const fileName = "上账导入数据.xlsx"
                        if ("download" in document.createElement("a")) {
                            // 非IE下载
                            const elink = document.createElement("a");
                            elink.download = fileName;
                            elink.style.display = "none";
                            elink.href = URL.createObjectURL(blob);
                            document.body.appendChild(elink);
                            elink.click();
                            URL.revokeObjectURL(elink.href); // 释放URL 对象
                            document.body.removeChild(elink);
                        } else {
                            // IE10+下载
                            navigator.msSaveBlob(blob, fileName);
                        }
                    }
                });
                reader.readAsText(blob, "utf-8");
            })
        },
        //下载导入数据
        download1(batchNo) {
            _http.dispatchExcelImport({ batchNo: batchNo, pageNum: 1, pageSize: 10 }).then(res => {
                const content = res;
                const blob = new Blob([content]);
                let reader = new FileReader(); // 创建读取文件对象
                reader.addEventListener("loadend", () => {
                    try {
                        let data = JSON.parse(reader.result);
                        if (data.code === 0) {
                            this.$message.error(data.msg || "服务异常");
                        }
                    } catch (err) {
                        const fileName = "下账导入数据.xlsx"
                        if ("download" in document.createElement("a")) {
                            // 非IE下载
                            const elink = document.createElement("a");
                            elink.download = fileName;
                            elink.style.display = "none";
                            elink.href = URL.createObjectURL(blob);
                            document.body.appendChild(elink);
                            elink.click();
                            URL.revokeObjectURL(elink.href); // 释放URL 对象
                            document.body.removeChild(elink);
                        } else {
                            // IE10+下载
                            navigator.msSaveBlob(blob, fileName);
                        }
                    }
                });
                reader.readAsText(blob, "utf-8");
            })
        },
        //关闭调账对话框
        clsoeAccountDialog() {
            this.ruleForm.companyId = ""
            this.ruleForm.price = ""
            this.ruleForm.command = ""
            this.accountDialogVisible = false
            this.$refs.ruleForm.resetFields();
        },
        //提交分账
        submit() {

            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    let params = {
                        amountPrice: this.ruleForm.price,
                        command: this.ruleForm.command,
                        companyId: this.ruleForm.companyId
                    }
                    _http.dispatchTransDiffFundToRedActivity(params).then(res => {
                        if (res.code == 1) {
                            this.$message.success("调账上账成功")
                            this.ruleForm.companyId = ""
                            this.ruleForm.price = ""
                            this.ruleForm.command = ""
                            this.diffFundBalance = 0
                            this.accountDialogVisible = false
                            this.$refs.ruleForm.resetFields();
                        }
                    }).catch(err => {

                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        //输入限制
        limitInput(value, name) {
            console.log(value);
            let str = (value && value.split("")) || [];
            let reg1 = /\d/;
            let reg2 = /\./;
            // 第一个字符不能为小数点
            if (str[0] == ".") {
                this.ruleForm[name] = "";
                return;
            }
            // 过滤掉除数字和小数点外的字符
            value = str.filter((e) => reg1.test(e) || reg2.test(e));
            // 匹配小数点后只能有两位小数
            let valJoin = value.join("");
            this.ruleForm[name] = valJoin.match(/^\d*(\.?\d{0,2})/g)[0] || null;
        },
        //
        handleChangeInquiry(val) {
            console.log(val)
            if (val != '') {
                // this.storeId = ''
                // this.getStoreSelectList()
                this.getCompanyAccount()
            }
        },
        getCompanyAccount() {
            _http.dispatchGetCompanyAccount({ companyId: this.ruleForm.companyId }).then(res => {
                if (res.code == 1) {
                    this.diffFundBalance = res.data.companyAccount.diffFundBalance
                }
            }).catch(err => {
                console.log(err)
            })
        },
        //操作或者查看上账记录
        operateaccount(row) {
            console.log(row)
            this.operateobj = row
            this.accountVisible = true
            _http.dispatchUIEventUpDownRollList({ batchNo: row.batchNo }).then(res => {
                console.log(res, 123)
                this.brandDataList = res.data
                if (this.up == "01" && this.record == "01") {
                if (res.data[0].state == "00") {
                    this.accountTitle = "操作上账-未开始"
                    this.closebutonname = "取消"
                    this.okbuttonname = "确定上账"
                }
                // if (res.data[0].state == "01") {
                //     this.accountTitle = "操作上账-执行中"
                //     this.closebutonname = "取消"
                //     this.okbuttonname = "刷新"
                // }
                if (res.data[0].state == "01") {
                    this.accountTitle = "操作上账-执行完成"
                    this.closebutonname = "关闭"
                    this.isshowok = false
                }
                if (res.data[0].state == "10") {
                    this.accountTitle = "操作上账-执行完成"
                    this.closebutonname = "取消"
                    this.okbuttonname = "重新上账"
                }
            }
            })
        },
        //操作或者查看下账记录
        operateaccount1(row) {
            console.log(this.up)
            console.log(this.record)
            this.okloading1 = false

            console.log(row)
            this.operateobj1 = row
            this.accountVisible1 = true
            _http.dispatchUIEventUpDownRollList({ batchNo: row.batchNo }).then(res => {
                console.log(res, 123)
                this.brandDataList1 = res.data
                if (this.up == "02" && this.record == "01") {
                if (res.data[0].state == "00") {
                    this.accountTitle1 = "操作下账-未开始"
                    this.closebutonname1 = "取消"
                    this.okbuttonname1 = "确定下账"
                }
                // if (res.data[0].state == "01") {
                //     this.accountTitle1 = "操作下账-执行中"
                //     this.closebutonname1 = "取消"
                //     this.okbuttonname1 = "刷新"
                // }
                if (res.data[0].state== "01") {
                    this.accountTitle1 = "操作下账-执行完成"
                    this.closebutonname1 = "关闭"
                    this.isshowok1 = false
                }
                if (res.data[0].state == "10") {
                    this.accountTitle1 = "操作下账-执行完成"
                    this.closebutonname1 = "取消"
                    this.okbuttonname1 = "重新下账"
                }
            }
            })
        },
        //关闭上账记录话框
        closeaccount() {
            this.operateobj = null
            this.accountVisible = false
            this.$nextTick(() => {
                this.accountTitle = "操作上账-未开始"
                this.closebutonname = "取消"
                this.okbuttonname = "重新上账"
                this.isshowok = true
            })
          
        },
        //关闭操作对话框
        closeaccount1() {
            console.log(123)
            this.operateobj1 = null
            this.accountVisible1 = false
            this.$nextTick(() => {
                this.accountTitle = "操作下账-未开始"
                this.closebutonname = "取消"
                this.okbuttonname = "重新下账"
                this.isshowok1 = true
            })

        },
        //上账
        accountupordown() {
            console.log(this.operateobj, 9999)
            console.log(this.brandDataList, 9989811)
            if (this.okbuttonname == "确定上账") {
                let arry = []

                for (let i = 0; i < this.brandDataList.length; i++) {
                    arry.push({
                        detailId: this.brandDataList[i].id,
                        note: this.brandDataList[i].note,
                    })

                }
                console.log(arry, 123)
                let obj = {
                    list: arry,
                    batchNo: this.operateobj.batchNo
                }
                console.log(obj, 1234)
                this.okloading = true
                _http.dispatchConfirm(obj).then(res => {
                    if (res.code == 1) {
                        this.$message.success("操作成功")
                        this.operateobj = null
                        this.accountVisible = false
                        this.accountTitle = "操作上账-未开始"
                        this.closebutonname = "取消"
                        this.okbuttonname = "重新上账"
                        this.isshowok = true
                        this.handleCurrentChange()
                    }
                }).finally(() => {
                    this.okloading = false
                })
            }
            if (this.okbuttonname == "刷新") {
                this.okloading = true
                _http.dispatchUIEventUpDownRollList({ batchNo: this.operateobj.batchNo }).then(res => {
                    console.log(res, 123)
                    this.brandDataList = res.data
                }).finally(() => {
                    this.okloading = false
                })
            }
            if (this.okbuttonname == "重新上账") {
                this.okloading = true
                _http.dispatchReConfirm({ batchNo: this.operateobj.batchNo }).then(res => {
                    if (res.code == 1) {
                        this.$message.success("操作成功")
                        this.operateobj = null
                        this.accountVisible = false
                        this.accountTitle = "操作上账-未开始"
                        this.closebutonname = "取消"
                        this.okbuttonname = "重新上账"
                        this.isshowok = true
                        this.handleCurrentChange()
                    }
                }).finally(() => {
                    this.okloading = false
                })
            }
        },
        //下账
        accountupordown1() {
            console.log(this.operateobj, 9999)
            console.log(this.brandDataList, 9989811)
            if (this.okbuttonname1 == "确定下账") {
                let arry = []

                for (let i = 0; i < this.brandDataList1.length; i++) {
                    arry.push({
                        detailId: this.brandDataList1[i].id,
                        note: this.brandDataList1[i].note,
                    })

                }
                console.log(arry, 123)
                let obj = {
                    list: arry,
                    batchNo: this.operateobj1.batchNo
                }
                console.log(obj, 1234)
                this.okloading1 = true
                _http.dispatchConfirm(obj).then(res => {

                    if (res.code == 1) {
                        this.$message.success("操作成功")
                        this.operateobj1 = null
                        this.accountVisible1 = false
                        this.accountTitle1 = "操作下账-未开始"
                        this.closebutonname1 = "取消"
                        this.okbuttonname1 = "重新下账"
                        this.isshowok1 = true
                        this.handleCurrentChangedown()
                    }
                }).finally(() => {
                    this.okloading1 = false
                })
            }
            if (this.okbuttonname1 == "刷新") {
                this.okloading1 = true
                _http.dispatchUIEventUpDownRollList({ batchNo: this.operateobj1.batchNo }).then(res => {
                    console.log(res, 123)
                    this.brandDataList1 = res.data
                }).finally(() => {
                    this.okloading1 = false
                })
            }
            if (this.okbuttonname1 == "重新下账") {
                this.okloading1 = true
                _http.dispatchReConfirm({ batchNo: this.operateobj1.batchNo }).then(res => {
                    if (res.code == 1) {
                        this.$message.success("操作成功")
                        this.operateobj1 = null
                        this.accountVisible1 = false
                        this.accountTitle1 = "操作下账-未开始"
                        this.closebutonname1 = "取消"
                        this.okbuttonname1 = "重新下账"
                        this.isshowok1 = true
                        this.handleCurrentChangedown()
                    }
                }).finally(() => {
                    this.okloading = false
                })
            }
        },
        seeItem(row) {
            console.log(row.enterpriseId)
            let routeData = this.$router.resolve({
                path: "/payment/PaybAccount",
                query: { merchantId: row.enterpriseId },
            });
            window.open(routeData.href, "_blank");
        },
        seeItem1(row) {
            console.log(row.enterpriseId)
            let routeData = this.$router.resolve({
                path: "/payment/PaybAccount",
                query: { merchantId: row.enterpriseId },
            });
            window.open(routeData.href, "_blank");
        },
        gotonext(row) {
            // let routeData = this.$router.resolve({
            //     path: "/payment/updownAccount",
            //     query: { batchNo: row.batchNo },
            // });
            // window.open(routeData.href, "_blank");
            let obj = {
                path: "/payment/updownAccount",
                query: { batchNo: row.batchNo },
                up: "01",
                record: "01"
            }
            this.$emit('childInput', obj);
        },
        gotonext1(row) {
            let obj = {
                path: "/payment/updownAccount",
                query: { batchNo: row.batchNo },
                up: "02",
                record: "01"
            }
            this.$emit('childInput', obj);
        }
    },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {

    // 备注说明
    .Remarks {
        width: 100%;
        height: 100%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; //控制行数
        overflow: hidden;
    }
}

.addparto {
    font-size: 12px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #ff8080;
    margin-top: 20px;
}

/deep/.el-form-item__label {
    text-align: left !important;
}

/deep/ .el-form-item:nth-child(1) {
    margin-bottom: 0 !important;
}
</style>